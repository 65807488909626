<template>
  <div>
    <MenuUser />
    <div class="zone-mon-profil wf-section">
      <div class="profil-bloc-coordonnees w-container">
        <div class="drop-bloc-coordonnees">
          <div class="drop-coordonnees-titre">
            <p class="paragraph-17">coordonnées</p><img :src="require('@/assets/images/ic_arrow_down.svg')" loading="lazy" width="20" height="20" alt="">
          </div>
          <div class="drop-coordonnees-content">
            <div class="profil-info-perso">
              <p class="profl-info-coordonnees locataire">
                Vous êtes : {{proprietaire.prenom}} {{proprietaire.nom}}<br>
                Mail : {{proprietaire.email}}<br>
                Téléphone : {{proprietaire.telephone}}
              </p>
              <p class="profl-info-coordonnees espace titre">Votre locataire :</p>
              <p class="profl-info-coordonnees locataire">
                Nom : {{user.prenom}} {{user.nom}}<br>
                Né(e) le : {{formatDate(user.date_naissance)}}<br>
                Mail : {{user.email}}<br>
                Téléphone : {{user.telephone}}<br>
                Date de prise d'effet à l&#x27;Assurance Zéro Dépôt : <span class="text-span-24">{{formatDate(souscription.logement.date_bail)}}</span>
              </p>
              <p class="profl-info-coordonnees espace titre">Votre logement :</p>
              <p class="profl-info-coordonnees locataire">
                Adresse : {{souscription.logement.adresse}}<br>
                Ville : {{souscription.logement.ville}}<br>
                Code postal : {{souscription.logement.cp}}<br>
              </p>
              <a href="javascript:void(0)" class="btn-changer-passe w-button" @click="forgot()">changer mon mot de passe</a>
            </div>
          </div>
        </div>
        <div class="drop-bloc-documents">
          <div class="drop-documents-titre">
            <p class="paragraph-17-copy">documents</p><img :src="require('@/assets/images/ic_arrow_down.svg')" loading="lazy" width="20" height="20" alt="">
          </div>
          <div class="drop-document-content">
            <div class="profil-document">
              <div class="profil-zone-contrat">
                <div class="profil-cercle-id contrat-profil"><img :src="require('@/assets/images/ic_pdf_blanc.svg')" loading="lazy" width="20" alt=""></div>
                <div class="div-block-16">
                  <p class="profil-txt-nom-contrat"><span class="profil-text-span-4">Assurance Zéro Dépôt</span><br>Date de souscription : {{formatDate(souscription.logement.date_bail)}}</p>
                  <a v-if="souscription.docs.contrat != null" :href="souscription.docs.contrat" target="_blank" class="button-5 w-button">voir mon contrat</a>
                  <a class="profil-liste-doc" :href="$store.api.urlAPI+'/docs/CG.pdf'" target="_blank">Conditions générales</a>
                  <a class="profil-liste-doc space" :href="$store.api.urlAPI+'/docs/PJ.pdf'" target="_blank">Protection juridique</a>
                  <a class="profil-liste-doc space" :href="$store.api.urlAPI+'/docs/idip.pdf'" target="_blank">Document d&#x27;information</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bloc-contact-support-profil">
          <div class="div-block-15">
            <p class="paragraph-9-v2">Vous avez des questions ou besoin d&#x27;assistance ?</p>
            <p class="paragraph-10-v2"><span class="text-span-6">Contactez</span> notre support client.</p>
          </div>
          <a @click="$parent.modal()" class="btn-cliquez-ici-v2 rose-v2 w-button">cliquez-ici</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MenuUser from '@/components/Menu/Utilisateur';
export default {
  name: "",
  components: {
    MenuUser
  },
  data(){
    return {
      ready:false,
      user: {
        nom: null,
        prenom: null,
        email: null
      },
      events:[],
      souscription:{},
      proprietaire:{}
    }
  },
  methods: {
    formatDate(date) {
      var tmp = date.split("-");
      if(tmp.length != 3){
        return "01/01/1970"
      }
      return tmp.reverse().join('/');
    },
    forgot(){
      this.$store.api.ajax('/users/forgot', this.proprietaire, res => {
        if(res.status === true){
          this.$toastr.s("merci de vérifier votre boite mail.");
        }
      })
    }
  },
  beforeMount() {
    this.$store.api.ajax('/home/proprietaire', null, res => {
      if(res.status === true){
        this.user = res.data.user
        this.events = res.data.events
        this.souscription = res.data.souscription
        this.proprietaire = res.data.proprietaire;
        this.ready = true;
      }
    })
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].className = "body-crm"
  }
}
</script>
<style lang="scss" scoped>
</style>

<template>
  <div>
    <Locataire v-if="$store.api.user.role === 3"/>
    <Proprietaire v-else/>
    <section class="d-none popup-support" ref="modalSC">
      <div class="popup-content popup-main">
        <div class="div-zone-cross">
          <div data-w-id="33942d34-3e29-a873-2cd3-05b4ac82f206" class="div-zone-image-cross"><img @click="modal()" :src="require('@/assets/images/ic_cross_close.svg')" loading="lazy" width="20" alt="" class="image-cross-close"></div>
        </div>
        <div class="div-popup-content">
          <p class="paragraph-19">Support client <span class="text-span-27">Pautions</span></p>
          <div class="ligne-separation-popup"></div>
          <p class="paragraph-21">Vous avez des questions ou besoin d&#x27;assistance ?N&#x27;hésitez pas à contacter notre équipe qui répondra à toutes vos questions, du lundi au vendredi, de 9h à 18h.</p>
          <p class="paragraph-22">Par téléphone au <a target="_blank" href="tel://+33970386826" class="link-2">09 70 38 68 26</a>
          </p>
          <p class="paragraph-23">Par mail à <a href="mailto:support.client@pautions.fr" class="link-3">support.client@pautions.fr</a>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Locataire from './Locataire';
import Proprietaire from './Proprietaire';
export default {
  name: "",
  components: {
    Locataire, Proprietaire
  },
  methods: {
    modal() {
      var elt = Object.values(this.$refs.modalSC.classList)
      var check = elt.find(c => c === 'd-none');
      if(check === undefined){
        this.$refs.modalSC.classList.add('d-none')
      }else{
        this.$refs.modalSC.classList.remove('d-none')
      }
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-3";
  },
}
</script>
<style>
.popup-support{
  min-height: 100%;
}
.popup-main{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 479px){
  .popup-support {
    display: block;
  }
  .paragraph-22 {
    padding-right: 0px;
  }
}
</style>
